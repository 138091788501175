body {
  margin: 0;
  background: var(--text-color);
  height: 100vh;
  /*background: linear-gradient(60deg, red, black);*/
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* This is where the self portrait is (line 12: "images/background_image.png")*/
  /* background-image: url("images/background_image.png");
  background-repeat: no-repeat;
  background-position: 0 100%;
  background-size: 18%; */
  /* background-clip: content-box; */
  /* background-position: bottom; */
  /* background-clip: content-box
  ; */
  background-attachment: fixed;
}

code {
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
