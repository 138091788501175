code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custombutton {
  background: rgb(249, 139, 139);
  border: 2px solid #000;
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  height: 20px;
  margin-left: 5px;
  margin-top: 10px;
  padding: 0;
  text-align: center;
  width: 80px;
  border-radius: 5px;
  color: black;
}
.custombutton:hover {
  background: rgb(249, 119, 119);
  border: 2px solid #000;
}
.custombutton:active {
  background: rgb(253, 106, 106);
  border: 2px solid #000;
}
.smaller-custom-button {
  background: rgb(249, 139, 139);
  inline-size: min-content;
  border: 2px solid #000;
  color: black;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  height: 20px;
  margin-left: 5px;
  margin-top: 10px;
  padding: 0;
  text-align: center;
  border-radius: 8px;
}
.smaller-custom-button:hover {
  background: rgb(249, 119, 119);
  inline-size: min-content;
  border: 2px solid #000;
  color: black;
}
.smaller-custom-button:active {
  background: rgb(253, 106, 106);
  inline-size: min-content;
  border: 2px solid #000;
  color: black;
}

.inputRectangle {
  border: 2px solid #000;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  height: 20px;
  margin-left: 0px;
  margin-top: 20px;
  text-align: center;
  width: 120px;
  border-radius: 15px;
}
.inputRectangle:hover {
  background-color: rgb(239, 239, 239);
  border: 2px solid #000;
}
.smaller-input-rectangle {
  border: 2px solid #000;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  height: 20px;
  margin-left: 5px;
  margin-top: 10px;
  text-align: center;
  width: 100px;
  border-radius: 12px;
}
.smaller-input-rectangle:hover {
  background-color: rgb(239, 239, 239);
  border: 2px solid #000;
}
.rectangle {
  background: rgb(251, 133, 133);
  border: 3px solid #000;
  font-size: 30px;
  font-weight: bold;
  line-height: 50px;
  height: 50px;
  margin-left: 0px;
  margin-top: 10px;
  text-align: center;
  width: 250px;
  border-radius: 20px;
  position: relative;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 50px 110px 50px;
  padding-left: 20px;
}
.name-rectangle {
  background: rgb(255, 255, 255);
  min-inline-size: 150px;
  inline-size: min-content;
  writing-mode: horizontal-tb;
  border: 2px solid #000;
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  height: 20px;
  margin-left: 0px;
  margin-top: 10px;
  text-align: center;
  border-radius: 5px;
}
.oval {
  background: rgb(183, 178, 178);
  border: 3px solid #000;
  font-size: 30px;
  font-weight: bold;
  line-height: 50px;
  height: 50px;
  margin-left: 0px;
  margin-top: 10px;
  text-align: center;
  width: 200px;
  border-radius: 50px;
}
.takeoutbox-icon {
  position: relative;
  width: 40px;
  height: initial;
  transform: translateX(5px) translateY(5px);
}

@media (min-width > 300px) {
  .custombutton {
    font-size: 20px;
    height: 25px;
    width: 100px;
    border-radius: 5px;
  }
  .smaller-custom-button {
    font-size: 17px;
    height: 25px;
  }
  .inputRectangle {
    height: 25px;
    width: 150px;
  }
  .smaller-input-rectangle {
    height: 25px;
  }
  .name-rectangle {
    height: 25px;
    border-radius: 8px;
    font-size: 20px;
  }
}
