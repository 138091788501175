/* PROJECT */
:root {
  color: #022449;
}
.document {
  height: initial;
  width: 50%;
}
.page {
  position: relative;
  height: calc(100% - 75px);
}
.information-grid {
  position: relative;
  width: 95%;
  align-items: center;
  justify-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 90%;
  height: fit-content;
  grid-gap: 20px;
  margin-top: 50px;
  color: var(--project-text-color);
  padding-bottom: 100px;
  border-top: 3px solid var(--whole-text-color);
}
.information-grid-no-line {
  position: relative;
  width: 95%;
  align-items: center;
  justify-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: 90%;
  height: fit-content;
  grid-gap: 20px;
  margin-top: 50px;
  color: var(--project-text-color);
  padding-bottom: 100px;
  border-top: none;
}
.information-row {
  width: 100%;
  display: grid;
  height: fit-content;
  grid-template-columns: 45% 45%;
  margin: 0px;
  margin-top: 10px;
  justify-items: center;
  grid-gap: 50px;
  align-items: center;
}
.information {
  position: relative;
  justify-content: center;
  width: 35vw;
  margin-top: 0px;
  color: var(--whole-text-color);
  overflow: visible;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 10px;
  background-color: var(--text-color);
  border-radius: 50px;
}
.project-picture {
  position: relative;
  height: 32vw;
  width: initial;
  margin-bottom: 50px;
  border-radius: 10px;
  margin-top: 30px;
  box-shadow: 0.5px 0.5px 10px 1px #0e2946;
}
.page-icon {
  position: absolute;
  right: 4%;
  width: min(5vw, 40px);
  height: initial;
}
.information-description {
  font-size: min(1.8vw, 30px);
}

.information-title h3 {
  color: var(--pop-color);
  margin: 0;
  font-size: min(2.5vw, 40px);
}
.page h2 {
  font-size: min(3vw, 40px);
  color: var(--whole-text-color);
  font-weight: bold;
}
#small {
  height: 18vw;
}

@media (max-width: 750px) {
  .information-grid {
    grid-template-columns: 90%;
  }
  .project-picture {
    height: 50vw;
    margin-bottom: 0px;
  }
  .page h2 {
    font-size: 5.5vw;
  }
  .information-grid {
    margin-top: 0px;
  }
  .information-description {
    font-size: 4vw;
  }
  .information {
    width: 80vw;
  }
  .information-title h3 {
    font-size: min(4vw, 40px);
  }
  .information-row {
    grid-template-columns: 100%;
    grid-gap: 0px;
  }
  #small {
    width: 40vw;
    height: initial;
  }
}
